import React, { useState } from "react";
import Layout from "../components/Layout";
import Seo from "../components/seo";
import styled from "styled-components";
import "@fontsource/open-sans";

const ContactPage = () => {
  const [formValues, setFormValues] = useState({
    name: "",
    phone: "",
    email: "",
    body: "",
  });
  const [formStatus, setFormStatus] = useState("empty");
  const submitForm = event => {
    event.preventDefault();

    setFormStatus("sending");
    fetch(
      "https://f5yd5yij83.execute-api.eu-west-2.amazonaws.com/default/contact-form",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formValues),
      }
    )
      .then(res => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then(res => {
        setFormStatus("sent");
      })
      .catch(res => {
        setFormStatus("error");
      });

    return false;
  };

  const setFormValue = event =>
    setFormValues({
      ...formValues,
      [event.currentTarget.name]: event.currentTarget.value,
    });

  return (
    <Layout descriptionText={"Contact"}>
      <Seo title="contact" />

      <ParentContainer>
        <FormContainer>
          {formStatus === "sent" ? (
            "Thanks for contacting with us, we'll try to get back to you shortly."
          ) : (
            <EmailForm action="#" onSubmit={event => submitForm(event)}>
              <NameAndPhoneContainer>
                <InputName
                  type="name"
                  name="name"
                  placeholder="Your name"
                  value={formValues.name}
                  onChange={event => setFormValue(event)}
                  disabled={formStatus === "sending"}
                  required
                ></InputName>
                <InputPhone
                  type="phone"
                  name="phone"
                  placeholder="Your phone number"
                  value={formValues.phone}
                  onChange={event => setFormValue(event)}
                  disabled={formStatus === "sending"}
                ></InputPhone>
              </NameAndPhoneContainer>
              <InputEmail
                type="email"
                name="email"
                placeholder="Your email"
                value={formValues.email}
                onChange={event => setFormValue(event)}
                disabled={formStatus === "sending"}
                required
              ></InputEmail>
              <InputMessage
                type="text"
                name="body"
                placeholder="Message"
                value={formValues.body}
                onChange={event => setFormValue(event)}
                disabled={formStatus === "sending"}
                required
              ></InputMessage>
              <SubmitButton type="submit">SEND MESSAGE</SubmitButton>
            </EmailForm>
          )}
        </FormContainer>
        <FormInfo>
          <GeneralInfoText>GENERAL INFORMATION</GeneralInfoText>
          <StyleLineS></StyleLineS>
          <InfoDescription>
            For all enquiries, please email us using the form below. Whether you
            need an explanation, have a question about artworks, collaborations
            or anything else, we're ready to answer all your questions.
          </InfoDescription>
          {/* <GeneralEmail>
          <strong>GENERAL EMAIL</strong>
            <p>miriampierzak@gmail.com</p>  
        </GeneralEmail> */}
        </FormInfo>
      </ParentContainer>
    </Layout>
  );
};

export default ContactPage;

const ParentContainer = styled.div`
  color: black;
  margin-left: 18vw;
  background-color: white;
  margin-top: 50px;
  padding: 10px;
  height: 650px;
  margin-right: 18vw;
  position: relative;
  display: flex;

  @media screen and (max-width: 800px) {
    margin-left: 10px;
    margin-right: 10px;
    flex-direction: column;
    height: 1000px;
    margin-top: 20px;
  }
`;

const FormContainer = styled.div`
  background-color: white;
  display: flex;
  flex-grow: 2;
  padding: 10px;
  position: relative;
  flex-basis: 0;
`;

const FormInfo = styled.div`
  background-color: white;
  flex-grow: 1;
  flex-basis: 0;
  flex-direction: column;
  padding: 10px;
`;

const EmailForm = styled.form`
  height: 100%;
  width: 100%;
  position: relative;
`;

const InputEmail = styled.input`
  width: 100%;
  padding: 12px 20px;
  margin-top: 12px;
  box-sizing: border-box;

  &:focus {
    background-color: #e7e7e7;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
    margin-top: 12px;
  }
`;

const InputName = styled.input`
  padding: 12px 20px;
  margin-top: 12px;
  box-sizing: border-box;
  flex-grow: 2;
  flex-basis: 0;

  &:focus {
    background-color: #e7e7e7;
  }

  @media screen and (max-width: 800px) {
    flex-grow: unset;
    flex-basis: unset;
    width: 100%;
  }
`;

const InputPhone = styled.input`
  padding: 12px 20px;
  margin-left: 20px;
  margin-top: 12px;
  box-sizing: border-box;
  flex-grow: 1;
  flex-basis: 0;

  &:focus {
    background-color: #e7e7e7;
  }

  @media screen and (max-width: 800px) {
    flex-basis: unset;
    flex-grow: unset;
    width: 100%;
    margin-left: 0px;
  }
`;

const InputMessage = styled.textarea`
  width: 100%;
  height: 200px;
  padding: 12px 20px;
  margin: 12px 0;
  top: 0;
  left: 0;
  box-sizing: border-box;

  &:focus {
    background-color: #e7e7e7;
  }
`;
const SubmitButton = styled.button`
  width: 100%;
  height: 50px;
  text-align: center;
  background-color: white;
  color: #3e3e3e;
  padding: 12px 20px;
  margin: 12px 0;
  font-weight: bold;

  cursor: pointer;

  &:hover {
    background-color: #3e3e3e;
    color: white;
    transition: all 0.2s ease-in;
  }
`;

const GeneralInfoText = styled.p`
  color: #7a7a7a;
  font-size: 14px;
  letter-spacing: 1.3px;
  font-family: "Open Sans";
  margin-top: 22px;
  width: 100%;
  font-weight: bold;
`;
const StyleLineS = styled.div`
  margin-top: 40px;
  position: relative;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;

  &:before {
    content: "";
    width: 100%;
    height: 1px;
    background: black;
  }
`;

const InfoDescription = styled.div`
  margin-top: 40px;
  color: #7a7a7a;
  font-size: 14px;
  font-family: "Open Sans";
  font-weight: 400;
  width: 100%;
  line-height: 1.5;
  letter-spacing: 3.4px;

  @media screen and (max-width: 1000px) {
    letter-spacing: 2.8px;
    line-height: 1.2;
    font-size: 14px;
  }

  @media screen and (max-width: 800px) {
    line-height: 1.5;
    letter-spacing: 3.4px;
  }
`;

const GeneralEmail = styled.div`
  margin-top: 24px;
  width: 100%;

  & strong {
    color: #7a7a7a;
    font-size: 14px;
    font-family: "Open Sans";
    letter-spacing: 1.5px;
  }

  & p {
    margin-top: 10px;
    color: #7a7a7a;
    font-size: 14px;
    font-family: "Open Sans";
  }
`;

const NameAndPhoneContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 800px) {
    display: block;
  }
`;
